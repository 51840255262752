 // Customizable Area Start
 //  @ts-nocheck
import React from "react";
import { Layout } from 'antd';
const { Footer } = Layout;
import facebook from "./assets/facebook.png";
import twitter from "./assets/twitter.png";
import linkedin from "./assets/linkedin.png";
import youtube from "./assets/youtube.png";


class CustomFooter extends React.Component {
    
    render(): React.ReactNode {
        return (
            <>
                <Footer style={{position:'relative', bottom:'0px', width:'100%', textAlign: 'center', backgroundColor: "#191075"}}>
                    <div style={{ justifyContent: 'center', alignItems: "center", display: "flex"}}>
                       <a href="https://www.facebook.com/" target="_blank"><img style={{ marginRight: '10px', height: '30px'}} src={facebook} alt="L" /></a>
                       <a href="https://twitter.com/" target="_blank"><img style={{ marginRight: '10px', height: '30px'}} src={twitter} alt="L" /></a>
                       <a href="https://www.linkedin.com/" target="_blank"><img style={{ marginRight: '10px', height: '30px'}} src={linkedin} alt="L" /></a>
                       <a href="https://www.youtube.com/" target="_blank"><img style={{ marginRight: '10px', height: '30px'}} src={youtube} alt="L" /></a>
                    </div>
                    <br/>
                    <div> 
                        <p style={{color:'white'}}> SHRM India |  <a style={{color:'white'}} href="mailto:SHRMIAwards@shrm.org" >email: SHRMIAwards@shrm.org</a> | Phone: 1800-103-2198 (Toll Free) | <a style={{color:'white'}} href="/privacy-policy" target="_blank">Privacy policy</a></p>
                    </div>
                    <div> 
                        <p style={{color:'rgb(241 239 245 / 63%)', fontSize:'12px'}}> @2024 SHRM India. All rights reserved.</p>
                    </div> 
                    
              </Footer>
              <div style={{ width:'100%', textAlign: 'center', backgroundColor: "white"}}> 
                        <p style={{ fontSize:'12px', padding:'15px 0px', margin:'0px', color:'#9C9C9C'}}> SHRM provides content as a service to its readers and members. It does not offer legal advice, and cannot guarantee the accuracy or suitability of its content for a particular purpose.</p>

                    </div> 
        
            </>
        );
    }

};
export default CustomFooter; 
 // Customizable Area End

